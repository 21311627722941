<template>
  <v-card flat class="rounded-xl">
    <v-card-title> </v-card-title>

    <v-card-text>
      <v-form
        ref="form"
        onSubmit="return false;"
        v-if="!isLoggingIn"
        v-model="isFormValid"
      >
        <v-row>
          <v-col :class="['my-0', 'py-0']" cols="12">
            <v-text-field
              :class="['mt-0']"
              :label="'Token'"
              :rules="[rules.required]"
              :value="JSON.stringify(this.token)"
              disabled
              outlined
              v-if="me.isSuperuser"
            ></v-text-field>
          </v-col>

          <v-col :class="['my-0', 'py-0']" cols="12">
            <v-autocomplete
              :items="loketEmployers.edges"
              :label="$tc('label.company', 2)"
              :rules="[rules.required]"
              :hint="
                loketEmployers.edges.length > 0
                  ? $t('label.success')
                  : $t('message.failedTryAgain')
              "
              cache-items
              chips
              disabled
              item-text="companyName"
              item-value="id"
              multiple
              outlined
              small-chips
              v-model="loketEmployers.edges"
            >
              <v-icon
                :color="loketEmployers.edges.length > 0 ? 'success' : 'error'"
                slot="prepend-inner"
              >
                {{
                  loketEmployers.edges.length > 0 ? "verified_user" : "security"
                }}
              </v-icon>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-form>

      <v-row class="fill-height" align-content="center" justify="center" v-else>
        <v-col class="text-center" cols="12">
          {{ $t("message.loginWithYourAccount") }}
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
        <v-col class="text-center" cols="12">
          {{ $t("message.installationResumes") }}
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions v-if="!isLoggingIn">
      <v-spacer />
      <v-btn
        @click="back()"
        :class="['black--text', 'mr-2']"
        rounded
        small
        text
      >
        {{ $t("label.back") }}
      </v-btn>
      <v-btn
        @click="reAuth()"
        :class="['black--text', 'mr-2']"
        rounded
        small
        text
      >
        {{ $t("label.retry") }}
      </v-btn>
      <v-btn
        :class="['white--text']"
        :color="'success'"
        :disabled="!isFormValid"
        @click="next()"
        rounded
        small
      >
        {{ $t("label.next") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import gql from "graphql-tag";
import rules from "@/utils/rules.js";

export default {
  name: "loket-install",
  props: {
    getAuthCode: {
      type: Boolean,
      default: false,
    },
    selectedCompanies: {
      type: Array,
      default: [],
    },
    step: {
      type: Number,
      default: 1,
    },
    token: {
      type: Object,
      default: {},
    },
  },
  components: {},

  apollo: {
    loketToken: {
      query: gql`
        query loketToken($redirectUri: String!, $code: String!) {
          loketToken(redirectUri: $redirectUri, code: $code) {
            accessToken
            tokenType
            expiresIn
            refreshToken
            expiresAt
          }
        }
      `,
      variables() {
        return {
          redirectUri: this.redirectUri,
          code: this.$router.currentRoute.query.code,
        };
      },
      fetchPolicy: "network-only",
      update: (data) => data.loketToken,
      skip: true,
      result() {
        this.$apollo.queries.loketEmployers.skip = false;

        this.$emit("update:token", this.loketToken);
      },
    },

    loketEmployers: {
      query: gql`
        query loketEmployers($accessToken: String!) {
          loketEmployers(accessToken: $accessToken) {
            edges {
              id
              companyName
            }
          }
        }
      `,
      variables() {
        return {
          accessToken: this.loketToken.accessToken,
        };
      },
      fetchPolicy: "network-only",
      update: (data) => data.loketEmployers,
      skip: true,
    },
  },
  data: function () {
    return {
      clientId: "TapTarget",
      isFormValid: false,
      isLoggingIn: true,
      localSelectedCompanies: this.selectedCompanies,
      redirectUri: null,
      loketToken: {},
      loketEmployers: {
        edges: [],
      },
    };
  },
  computed: {
    me() {
      return this.$store.state.user.me;
    },
  },
  watch: {
    getAuthCode(val) {
      if (val) {
        this.goToExternalLogin();
      }
    },

    localSelectedCompanies(val) {
      // Update the parent's selectedCompanies when the localSelectedCompanies changes
      this.$emit("update:selectedCompanies", val);
    },
  },
  created() {
    this.rules = rules;

    var baseUrl = window.location.origin;
    if (baseUrl == "http://localhost:8080") {
      this.redirectUri = "https://www.taptarget.io/";
    } else {
      this.redirectUri = `${baseUrl}/${this.$route.params.externalId}`;
    }

    if (this.$router.currentRoute.query.code) {
      this.$apollo.queries.loketToken.skip =
        !this.$apollo.queries.loketToken.skip;

      this.isLoggingIn = false;

      this.next();
    }
  },
  mounted() {},
  methods: {
    back() {
      this.$emit("update:step", this.step - 1);
    },

    goToExternalLogin() {
      window.location.href =
        "https://oauth.loket.nl/authorize?client_id=" +
        this.clientId +
        "&response_type=code&scope=all&state=123&redirect_uri=" +
        this.redirectUri;
    },

    next() {
      this.$emit("update:step", this.step + 1);
    },

    reAuth() {
      this.$router.replace({ query: null });
      this.back();
    },
  },
};
</script>
